import React from 'react';
import {Container, Heading, Text, Wrapper, Subheading, ContentWrapper} from './OtherPageElements';

import Footer from "../Footer";

const Careers = () => {
	return (
		<>
			<Container>
				<Wrapper>

					<Heading>Careers</Heading>
					<ContentWrapper>
						<Text>
							There are currently no open positions.
							<br/><br/>
							Please stay tuned for updates regarding careers at ASAP Computer Parts.
						</Text>
					</ContentWrapper>
				</Wrapper>
				<Footer/>
			</Container>

		</>
	);
};

export default Careers;