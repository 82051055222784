import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import {
	homeObjOne,
	homeObjTwo,
	homeObjThree
} from '../components/InfoSection/Data';
import Services from '../components/Brands';
import Brands from "../components/Brands";
import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
   body {
	  height: 100vh;
	}
`;

function Home() {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};
	return (
		<>
			<GlobalStyle/>
			<Sidebar isOpen={isOpen} toggle={toggle}/>
			<Navbar toggle={toggle}/>
			<HeroSection/>
			<InfoSection {...homeObjOne} />
			<InfoSection {...homeObjTwo} />

			<Brands/>
			<InfoSection {...homeObjThree} />
			<Footer/>
		</>
	);
}

export default Home;
