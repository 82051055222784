import React from 'react';


import {Container, Heading, Text, Wrapper} from './OtherPageElements';
import Footer from "../Footer";

const About = () => {
	return (
		<>



			<Container>
				<Wrapper>
					<Heading> About </Heading>
					<Text>
						ASAP Computer Parts, Inc. was founded in 1999 with the goal of providing the public with the highest
						quality computer parts delivered as soon as possible. Over the past 22+ years we have grown into a global
						network with the experience needed to provide quality solutions to businesses of any scale.
					</Text>
				</Wrapper>
				<Footer/>
			</Container>
		</>
	);
};

export default About;