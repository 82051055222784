import React from 'react';


import {Container, Heading, Text, Wrapper} from './OtherPageElements';
import Footer from "../Footer";

const COVID = () => {
	return (
		<>



			<Container>
				<Wrapper>
					<Heading> COVID-19 </Heading>
					<Text>
						Please stay tuned for more information regarding ASAP Computer Parts and how we're taking on the
						COVID-19 pandemic.
					</Text>
				</Wrapper>
				<Footer/>
			</Container>
		</>
	);
};

export default COVID;