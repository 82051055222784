import React, { useEffect, useState } from "react";
import styled from "styled-components";
import times from "lodash/times";
import Marquee from "react-marquee-slider";
import { withSize } from "react-sizeme";
import { nanoid } from "nanoid";

import Cisco from '../../images/cisco.svg';
import Compaq from '../../images/compaq.svg';
import Dell from '../../images/dell.svg';
import HP from '../../images/hp.svg';
import IBM from '../../images/ibm.svg';
import Juniper from '../../images/juniper.svg';

import {
	ServicesContainer,
	ServicesH1,
	ServicesWrapper,
	ServicesCard,
	ServicesIcon,
	ServicesH2,
	ServicesP
} from './ServicesElements';

const Photo = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 4px;
  margin-left: ${(props) => (props.offset === "true" ? props.scale * 7 : props.scale * 87)}px;
  margin-right: ${(props) => (props.offset === "true" ? props.scale * 80 : 0)}px;
`;

const photos = [
	Cisco,
	Compaq,
	Dell,
	HP,
	IBM,
	Juniper,
];

const Index = ({ size }) => {
	const [key, setKey] = useState(nanoid());

	useEffect(() => {
		setKey(nanoid());
	}, [size, size.width]);

	let scale = 0.5;

	if (size && size.width > 800) {
		scale = 0.65;
	}

	if (size && size.width > 1100) {
		scale = 0.8;
	}

	if (size && size.width > 1400) {
		scale = 1;
	}

	return (
		<ServicesContainer id='services' >
			<ServicesH1>Brands We Offer</ServicesH1>

			<ServicesWrapper>
				<Marquee key={key} velocity={25}>
					{times(6, Number).map((id) => (

						<Photo src={photos[id]} alt="" key={`marquee-example-people-${id}`} scale={scale} />
					))}
				</Marquee>
			</ServicesWrapper>

			{/*<div style={{ height: scale * 200 }}>*/}
			{/*	<Marquee key={key} velocity={25}>*/}
			{/*		{times(7, Number).map((id) => (*/}
			{/*			<Photo*/}
			{/*				src={photos[id + 7]}*/}
			{/*				alt=""*/}
			{/*				key={`marquee-example-people-${id + 7}`}*/}
			{/*				offset="true"*/}
			{/*				scale={scale}*/}
			{/*			/>*/}
			{/*		))}*/}
			{/*	</Marquee>*/}
			{/*</div>*/}

		</ServicesContainer>
	);
};

export default withSize()(Index);