import React from "react";
import styled from "styled-components";
import {Link} from 'react-router-dom';
import Footer from "../Footer";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: #fff;
  background: #000715;
  

`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 2rem 5rem;

  @media screen and (max-width: 480px) {
    padding: 2rem 4rem;
  }
`;

export const Text = styled.p`
  font-family: monospace;
  color: #8f8f8f;
`;

export const ExitLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #3797cb;
    transition: 0.3s ease-out;

`;

export const Subheading = styled.strong`
color: white;
`;

export const ContentWrapper = styled.div`
  overflow-y: scroll;
  max-height: 60vh;
  margin-bottom: -5rem;
`;