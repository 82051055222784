import React from 'react';
import './App.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import About from "./components/OtherPages/about";
import PrivacyPolicy from "./components/OtherPages/privacypolicy";
import TermsOfService from "./components/OtherPages/termsofservice";
import Careers from "./components/OtherPages/careers";
import COVID from "./components/OtherPages/covid";
import Contact from "./components/OtherPages/contact";

function App() {
	return (
		<Router basename='/'>
			<Switch>
				<Route path='/' component={Home} exact/>
				<Route path='/terms-of-service' component={TermsOfService} exact/>
				<Route path='/privacy-policy' component={PrivacyPolicy} exact/>
				<Route path='/about' component={About} exact/>
				<Route path='/careers' component={Careers} exact/>
				<Route path='/covid' component={COVID} exact/>
				<Route path='/contact' component={Contact} exact/>
			</Switch>
		</Router>
	);
}

export default App;
