import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll';
import {
	MobileIcon,
	Nav,
	NavbarContainer,
	NavItem,
	NavLinks,
	NavLogo,
	NavLogoImg,
	NavMenu,
	NavBtn,
	NavBtnLink
} from './NavbarElements';
import Logo from '../../images/asap-logo-updated.svg';

const Navbar = ({toggle}) => {
	const [scrollNav, setScrollNav] = useState(false);
	//const root = document.querySelector("#root");


	const changeNav = () => {
		if (window.scrollY >= 80) {
			setScrollNav(true);
		} else {
			setScrollNav(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', changeNav);
	}, []);

	const toggleHome = () => {
		scroll.scrollToTop();
	};

	return (
		<>
			<IconContext.Provider value={{color: '#fff'}}>
				<Nav scrollNav={scrollNav}>
					<NavbarContainer>
						<NavLogo onClick={toggleHome} to='/'>
							<NavLogoImg src={Logo} />
							ASAP Computer Parts
						</NavLogo>
						<MobileIcon onClick={toggle}>
							<FaBars/>
						</MobileIcon>
						<NavMenu>
							<NavItem>
								<NavLinks
									to='about'
									smooth={true}
									duration={500}
									spy={true}
									exact='true'
									offset={-80}
								>
									About
								</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinks
									to='discover'
									smooth={true}
									duration={500}
									spy={true}
									exact='true'
									offset={-80}
								>
									Discover
								</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinks
									to='services'
									smooth={true}
									duration={500}
									spy={true}
									exact='true'
									offset={-80}
								>
									Services
								</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinks
									to='contact'
									smooth={true}
									duration={500}
									spy={true}
									exact='true'
									offset={-80}
								>
									Contact
								</NavLinks>
							</NavItem>
						</NavMenu>
					</NavbarContainer>
				</Nav>
			</IconContext.Provider>
		</>
	);
};

export default Navbar;
