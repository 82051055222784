export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Premium Service',
  headline: 'Over 22 years in  the industry',
  description:
    'We have the expertise and experience to get you what you need when you need it.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/svg-1.svg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false,
  to: '/about'
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Worldwide Delivery',
  headline: 'Shipped to 47+ countries to date',
  description:
    'We have you covered no matter where you are located.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/svg-2.svg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'contact',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Reach out',
  headline: 'Don\'t miss out ',
  description:
    "Take advantage of live tech support and assistance.",
  buttonLabel: 'Contact Us',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};
