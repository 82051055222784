import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import {
	FooterContainer,
	FooterWrap,
	FooterLinksContainer,
	FooterLinksWrapper,
	FooterLinkTitle,
	FooterLink,
	SocialMedia,
	SocialMediaWrap,
	SocialLogo,
	WebsiteRights,
	FooterLogoImg, Information, Policies,
} from './FooterElements';
import Logo from '../../images/asap-logo-updated.svg';
import {Button2} from "../ButtonElements";

const Footer = () => {
	const toggleHome = () => {
		scroll.scrollToTop();
	};
	return (
		<FooterContainer>
			<FooterWrap>
				<FooterLinksContainer>
					<FooterLinksWrapper>

						<Information>
							<FooterLinkTitle>Information</FooterLinkTitle>
							<FooterLink to='/'>Home</FooterLink>
							<FooterLink to='/about'>About Us</FooterLink>
							<FooterLink to='/careers'>Careers</FooterLink>
							<FooterLink to='/covid'>COVID-19</FooterLink>
						</Information>

						<Policies>
							<FooterLinkTitle>Policies</FooterLinkTitle>
							<FooterLink to='/terms-of-service'>Terms of Service</FooterLink>
							<FooterLink to='/privacy-policy'>Privacy Policy</FooterLink>
						</Policies>

							<Button2 to='/contact'>
								Contact
							</Button2>

					</FooterLinksWrapper>

				</FooterLinksContainer>
				<SocialMedia>
					<SocialMediaWrap>
						<SocialLogo to='/' onClick={toggleHome}>
							<FooterLogoImg src={Logo}/> ASAP Computer Parts
						</SocialLogo>
						<WebsiteRights>ASAP Computer Parts, Inc. © 2020 All rights reserved.</WebsiteRights>
					</SocialMediaWrap>
				</SocialMedia>
			</FooterWrap>
		</FooterContainer>
	);
};

export default Footer;
