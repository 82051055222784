import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const FooterContainer = styled.footer`
  background-color: #000715;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;

  @media screen and (max-width: 420px) {
    padding-top: 32px;

  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;


  @media screen and (max-width: 640px) {
    display: grid;
    padding-top: 32px;

  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  justify-content: center;


  @media screen and (max-width: 640px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 11rem;
  }
`;


export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #3797cb;
    transition: 0.3s ease-out;
  }
`;

export const Information = styled(FooterLinkItems)`

  @media screen and (max-width: 640px) {
    grid-area: 1 / 1 / 3 / 2;
  }

`;

export const Policies = styled(FooterLinkItems)`
  @media screen and (max-width: 640px) {
    grid-area: 1 / 2 / 2 / 3;
  }

`;

export const Contact = styled(FooterLinkItems)`
  @media screen and (max-width: 640px) {
    margin-top: -3px;
    grid-area: 2 / 2 / 3 / 3;
  }

`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;

  @media screen and (max-width: 400px) {
    font-size: 1rem;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;

  @media screen and (max-width: 400px) {
    font-size: .75rem;
  }

  @media screen and (max-width: 360px) {
    font-size: .6rem;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const FooterLogoImg = styled.img`
  height: 3rem;
  width: 3rem;
  margin-right: 6px;
  margin-top: 0px;
`;

export const Button = styled(Link)`
  background: #3797cb;
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  border-radius: 1rem;

  @media screen and (max-width: 400px) {
    font-size: 1rem;
  }


`;