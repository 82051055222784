import React from 'react';
import {
	Container,
	FormWrap,
	Icon,
	FormContent,
	Form,
	FormH1,
	FormLabel,
	FormInput,
	FormButton,
	Text, TextInput
} from '../Form/FormElements';
import Footer from "../Footer";

const Contact = () => {
	return (
		<>
			<Container>
				<FormWrap>
					<Icon to='/'></Icon>
					<FormContent>
						<Form action='#'>
							<FormH1>Contact</FormH1>
							<FormLabel htmlFor='for'>Email</FormLabel>
							<FormInput type='email' required />
							<FormLabel htmlFor='for'>Message</FormLabel>
							<TextInput type='Message' required />
							<FormButton type='submit'>Submit</FormButton>
						</Form>
					</FormContent>
				</FormWrap>
			</Container>
			<Footer/>
		</>
	);
};

export default Contact;